import linkData from "../data/link.json";
import "./styles/linkTree.scss"

function LinkElement(props) {

    return (
        <a className="link-element" href={props.linkInfo["url"]}>
            <img src={props.linkInfo["icon"]} alt=""/>
            <div className="link-info">
                <p className="link-title">
                    {props.linkInfo["title"]}
                </p>
                <p className="link-description">
                    {props.linkInfo["description"]}
                </p>
            </div>
        </a>
    )
}

function LinkGroup(props) {
    return (
        <div className="link-group">
            <p className="group-title">
                {props.groupName}
            </p>
            <hr />
            <div>
                <ol className="link-list">
                    {
                        (linkData[props.groupName]).map((linkInfo, index) => {
                            console.log(linkInfo)
                            return (
                                <li key={index}>
                                    <LinkElement linkInfo={linkInfo} />
                                </li>
                            )
                        })
                    }
                </ol>
            </div>
        </div>
    )
}

export default function LinkTree() {

    return (
        <div>
            <ol>
                {
                    Object.keys(linkData).map((groupName, index) => {
                        return (
                            <li key={index}>
                                <LinkGroup groupName={groupName} />
                            </li>
                        )
                    })
                }
            </ol>
        </div>
    )
}