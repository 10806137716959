import './App.css';

import LinkTree from "./component/linkTree"

import websiteConfigData from './data/website-conf.json';

function App() {
  return (
    <div className="App">
      <header>
        <h1>
          {websiteConfigData['site-org-name']}
        </h1>
        <h2>
          {websiteConfigData["site-title"]}
        </h2>
      </header>
      <main>
        <LinkTree />
      </main>
    </div>
  );
}

export default App;
